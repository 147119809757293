import { React, useState, useEffect, useLayoutEffect, useRef } from 'react';
import { createHttpClient } from '../../utilities/httpclient';
import createUserSessionActivity from '../../utilities/usersessiontracking';
import { AbdsButton, AbdsSelect, AbdsSelectOption, AbdsModal, AbdsInput } from '@abds/react-bindings';
import { APPLICATION_API_ENDPOINT } from '../../utilities/constants';

export default function NewExpirationEventEmailRequestModal(props)
{
    const [visible, setVisible] = useState(false);
    const [emailTypeList, setEmailTypeList] = useState(null);
    const [notificationEmailType, setNotificationEmailType] = useState(-1);
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [emailRecipient, setEmailRecipient] = useState('');
    const [responseMessage, setResponseMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [expirationEventGUID, setExpirationEventGUID] = useState(props.expirationEventGUID);

    const rEmailRecipient = useRef(null);
    const rEmailTypes = useRef(null);

    const onChangeEmailRecipient = e => setEmailRecipient(e.target.value);

    const onChangeNotificationEmailType = e => setNotificationEmailType(e.target.value);

    const populateEmailTypes = async () => {
        const httpclient = createHttpClient();
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/certificate/getemailtypes`)
            .then(response => setEmailTypeList(response?.data || []))
            .catch(e => setErrorMessage(e.response?.data || e.message));
    };

    const submitTestEmailRequest = async () => {
        setErrorMessage('');
        setResponseMessage('');
        const httpclient = createHttpClient();
        const criteria = {
            'Email': emailRecipient,
            'ApplicationTypeKey': +notificationEmailType,
            'ExpirationEventGUID': expirationEventGUID
        };
        createUserSessionActivity(window.location.pathname, 'onClickSubmitEmailRequest', criteria);
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/certificate/createemailrequest`, criteria)
            .then(response => {
                if (!!(response.data?.success))
                {
                    setResponseMessage(response.data.message);
                    setDisableSubmitButton(true);
                    let timeoutID = setTimeout(() => {
                        clearTimeout(timeoutID);
                        closeThisModal();
                    }, 3000);
                }
            })
            .catch(e => setErrorMessage(e.response?.data || e.message));
    };

    const onClickShowEmailRequestModal = e => {
        e.preventDefault();
        setVisible(true);
    };

    const onClickCloseEmailRequestModal = e => {
        e.preventDefault();
        closeThisModal();
    };

    const onClickSubmitEmailRequest = e => {
        e.preventDefault();
        submitTestEmailRequest();
    };

    const closeThisModal = () => {
        setEmailRecipient('');
        setNotificationEmailType('');
        setErrorMessage('');
        setResponseMessage('');
        setVisible(false);
    };

    useLayoutEffect(() => {
        const hasAllData = (!!(notificationEmailType?.length > 0) && !!(emailRecipient?.length > 0));
        setDisableSubmitButton(!hasAllData);
    }, [notificationEmailType, emailRecipient])

    useEffect(() => {
        rEmailTypes.current?.addEventListener('abdsChange', onChangeNotificationEmailType);
        rEmailRecipient.current?.addEventListener('abdsInput', onChangeEmailRecipient);

        if (!!visible)
        {
            setDisableSubmitButton(false);
            populateEmailTypes();
            const modal = document.querySelector('abds-modal[name="emailmodal"]');
            modal?.setAttribute('open', !modal.open);
        }
        else
        {
            const modal = document.querySelector('abds-modal[name="emailmodal"]');
            modal?.setAttribute('open', !modal.open);
        }

        return () => {
            rEmailTypes.current?.removeEventListener('abdsChange', onChangeNotificationEmailType);
            rEmailRecipient.current?.removeEventListener('abdsInput', onChangeEmailRecipient);
        };
    }, [visible, rEmailTypes, rEmailRecipient]);

    return (
        <>
            {
                !visible &&
                <div>
                    <a title="click here to generate test email notification" href="#" onClick={onClickShowEmailRequestModal}>
                        <div className="centered"><i className="bi bi-envelope-fill seventimes"></i></div>
                        <div className="centered">Request Test Email</div>
                    </a>
                </div>
            }
            {
                visible &&
                (
                    <AbdsModal header-text="Send Test Email" name="emailmodal">
                        <div slot="modal-body">
                            <p><em>NOTE: Your request will be processed within 15 minutes after submission</em></p>
                            {
                                !!(errorMessage?.length > 0) &&
                                <div className="emailmodal error">{errorMessage}</div>
                            }
                            {
                                !!(responseMessage?.length > 0) &&
                                <div className="emailmodal success">{responseMessage}</div>
                            }
                            <div>
                                <AbdsSelect
                                    name="notificationtype"
                                    width="full"
                                    ref={rEmailTypes}
                                    value={notificationEmailType}
                                    tooltip="Choose Notification Type"
                                    required="true"
                                    placeholder="-- Choose Notification Type --">
                                    {
                                        emailTypeList?.map(item => {
                                            return (
                                                <AbdsSelectOption
                                                    key={item.applicationTypeKey}
                                                    value={item.applicationTypeKey}>
                                                    {item.label}
                                                </AbdsSelectOption>
                                            );
                                        })
                                    }
                                </AbdsSelect>
                            </div>
                            <div>
                                <AbdsInput
                                    type="text"
                                    width="full"
                                    required="true"
                                    name="emailrecipient"
                                    ref={rEmailRecipient}
                                    value={emailRecipient}
                                    tooltip="Enter up to five (5) email address, delimited by semicolon, colon or comma"
                                    placeholder="Enter Email Address(es)">
                                    Enter Email Address
                                </AbdsInput>
                            </div>
                        </div>
                        <div slot="modal-footer">
                            <AbdsButton
                                onClick={onClickCloseEmailRequestModal}
                                appearance="outline"
                                palette="brand">
                                Cancel
                            </AbdsButton>
                            &nbsp;&nbsp;
                            <AbdsButton
                                onClick={onClickSubmitEmailRequest}
                                disabled={disableSubmitButton}
                                ref={props.modalSubmitButton}>
                                Submit
                            </AbdsButton>
                        </div>
                    </AbdsModal>
                )
            }
        </>
    );
}
import { React, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createHttpClient, handleRemoteError } from '../utilities/httpclient';
import { AbdsButton, AbdsLabel, AbdsInput } from '@abds/react-bindings';
import Base64 from 'crypto-js/enc-base64';
import SHA512 from 'crypto-js/sha512';
import createUserSessionActivity from '../utilities/usersessiontracking';
import { createDisplayAreaMessengerContext, DisplayAreaMessenger } from './displayareamessenger/DisplayAreaMessenger';
import { APPLICATION_API_ENDPOINT } from '../utilities/constants';
import { useKeyPressEnter } from '../customhooks/useKeyPressEnter';

import '../styles/accountsettings.css';

export function AccountSettings (props)
{
    const rPassword = useRef(null);
    const rNewPassword = useRef(null);
    const rConfirmNewPassword = useRef(null);
    const rSubmitButton = useRef(null);
    
    let [userID, setUserID] = useState(-1);
    let [userName, setUserName] = useState('');
    let [userEmail, setUserEmail] = useState('');
    let [userCompanyName, setUserCompanyName] = useState('(N/A)');
    let [userRole, setUserRole] = useState('(N/A)');
    let [pwd, setPwd] = useState('');
    let [newpwd, setNewPwd] = useState('');
    let [confirmpwd, setConfirmPwd] = useState('');
    let [code, setCode] = useState(null);
    let [messageContext, setMessageContext] = useState({});

    const navigate = useNavigate();
    const { pUserID } = useParams();

    const getUserDataByKey = async userKey => {
        const httpclient = createHttpClient();
        const data = { 'UserID': userKey };
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/systemaccess/accountsettings`, data).then(response => {
            if (!response?.data)
            {
                handleRemoteError(navigate, null, 'Unexpected error with retrieving account settings');
                return;
            }
            setUserID(+response.data.userID || userKey);
            setUserName(response.data.name);
            setUserEmail(response.data.email);
            setUserCompanyName(response.data.companyName || "(N/A)");
            setUserRole(response.data.userRole || "(N/A)");
            setCode(response?.data?.code || null);
        })
        .catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    const onChangePassword = e => setPwd(e.target.value);

    const onChangeNewPassword = e => setNewPwd(e.target.value);

    const onChangeConfirmPassword = e => setConfirmPwd(e.target.value);

    const showErrorMessage = msg => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        messageContext.error.message = msg;
        setMessageContext(messageContext);
    };

    const validatePasswordRequirements = value => {
        const PW_L = 8;
        const PW_MX_NUM = 1;
        const PW_MX_UC = 1;
        const PW_MX_LC = 1;

        let A_NUM = 0;
        let A_UC = 0;
        let A_LC = 0;

        value = value || '';
        let errors = [];
        const letters = 'abcdefghijklmnopqrstuvwxyz';
        const upperLetters = letters.toUpperCase().split('');
        const lowerLetters = letters.split('');
        const numbers = '0123456789'.split('');

        if (value.length < PW_L)
            errors.push(`Password must be at least ${PW_L} characters in length`);

        for (let i = 0; i < lowerLetters.length; i++)
            if (value.includes(lowerLetters[i]))
                A_LC += 1;

        if (A_LC < PW_MX_LC)
            errors.push(`Password must contain at least ${PW_MX_LC} lower-case letter`);

        for (let i = 0; i < upperLetters.length; i++)
            if (value.includes(upperLetters[i]))
                A_UC += 1;

        if (A_UC < PW_MX_UC)
            errors.push(`Password must contain at least ${PW_MX_UC} upper-case letter`);

        for (let i = 0; i < numbers.length; i++)
            if (value.includes(numbers[i]))
                A_NUM += 1;

        if (A_NUM < PW_MX_NUM)
            errors.push(`Password must contain at least ${PW_MX_NUM} number`);

        return errors;

    };

    const onCancelSettings = e => {
        e.preventDefault();
        createUserSessionActivity(window.location.pathname, 'onCancelSettings');
        setMessageContext({});
        (isNaN(userID) || +userID <= 0 || getUserDataByKey(+userID));
        messageContext = createDisplayAreaMessengerContext();
        messageContext.information.message = "Request canceled; no changes were made to your settings";
        setMessageContext(messageContext);
        clearAllFields();
    };

    const clearAllFields = () => {
        setPwd('');
        setNewPwd('');
        setConfirmPwd('');
    };

    const createHash = text => (SHA512(text)).toString(Base64);

    const onClickSaveSettings = async e => {
        e.preventDefault();
        setMessageContext({});

        if (!checkPassword())
            return;

        const data = {
            'UserID': +userID,
            'NewCode': createHash(newpwd)
        };
        createUserSessionActivity(window.location.pathname, 'onClickSaveSettings', data);
        const httpclient = createHttpClient();
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/systemaccess/saveaccountsettings`, data).then(response => {
            messageContext = createDisplayAreaMessengerContext();

            if (!response?.data)
            {
                messageContext.error.message = "There was a problem with your request.";
            }
            else
            {
                messageContext.success.message = "Settings were successfully saved!";
                clearAllFields();
            }

            setMessageContext(messageContext);
        })
        .catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    const checkPassword = () => {
        messageContext = createDisplayAreaMessengerContext();
        pwd = (pwd || '').toString();
        confirmpwd = (confirmpwd || '').toString();
        newpwd = (newpwd || '').toString();

        if (createHash(pwd) !== code && code != null)
            messageContext.error.details.push("Your current password does not match the entered value");

        if (confirmpwd.length === 0 || newpwd.length === 0 || pwd.length === 0)
            messageContext.error.details.push("All password fields are required");

        if (newpwd === pwd && pwd !== '' && newpwd !== '')
            messageContext.error.details.push("New password is the same as current password");

        if (newpwd !== confirmpwd)
            messageContext.error.details.push("Confirmed password does not match new password");

        const pwResultsNewPwd = validatePasswordRequirements(newpwd);

        for (let i = 0; i < pwResultsNewPwd.length; i++)
            messageContext.error.details.push(pwResultsNewPwd[i]);

        if (messageContext.error.details.length > 0)
            setMessageContext(messageContext);

        return !!(messageContext.error.details.length === 0);
    };

    useKeyPressEnter(rSubmitButton);

    useEffect(() => {
        createUserSessionActivity(window.location.pathname);
        ( +pUserID <= 0 || getUserDataByKey(+pUserID) );
    }, []);

    useEffect(() => {
        rPassword.current?.addEventListener('abdsInput', onChangePassword);
        rNewPassword.current?.addEventListener('abdsInput', onChangeNewPassword);
        rConfirmNewPassword.current?.addEventListener('abdsInput', onChangeConfirmPassword);

        return () => {
            rPassword.current?.removeEventListener('abdsInput', onChangePassword);
            rNewPassword.current?.removeEventListener('abdsInput', onChangeNewPassword);
            rConfirmNewPassword.current?.removeEventListener('abdsInput', onChangeConfirmPassword);
        };
    }, [rPassword, rNewPassword, rConfirmNewPassword]);

    return (
        <div>
            <h3>Account Settings</h3>

            <hr className="abcblue" />

            <DisplayAreaMessenger context={messageContext} />

            <h4>Profile</h4>

            <div data-role="userprofile">
                <div><span className="usericon bi bi-person-circle"></span></div>
                <div>
                    <div data-role="profilegroup">
                        <AbdsLabel>Full Name</AbdsLabel>
                        <div>{userName}</div>
                    </div>
                    <div data-role="profilegroup">
                        <AbdsLabel>Email</AbdsLabel>
                        <div>{userEmail}</div>
                    </div>
                    <div data-role="profilegroup">
                        <AbdsLabel>Company Name</AbdsLabel>
                        <div>{userCompanyName}</div>
                    </div>
                    <div data-role="profilegroup">
                        <AbdsLabel>Your Role</AbdsLabel>
                        <div>{userRole}</div>
                    </div>
                </div>
            </div>

            <hr className="abcblue" />

            <h4>Security</h4>

            <div data-role="userprofile">
                <div><span className="usericon hidden bi bi-person-circle"></span></div>
                <div>
                    <div data-role="profilegroup">
                        <AbdsInput
                            type="password"
                            width="full"
                            name="pwd"
                            ref={rPassword}
                            required
                            value={pwd}
                            tooltip="Enter current password here"
                            placeholder="Enter current password here">
                            Password
                        </AbdsInput>

                    </div>
                    <div data-role="profilegroup">

                        <AbdsInput
                            type="password"
                            width="full"
                            name="newpwd"
                            ref={rNewPassword}
                            required
                            value={newpwd}
                            tooltip="Minimum eight (8) characters, with one (1) uppercase letter, one (1) lowercase letter and one (1) number"
                            placeholder="Enter password here">
                            New Password
                        </AbdsInput>

                    </div>
                    <div data-role="profilegroup">

                        <AbdsInput
                            type="password"
                            width="full"
                            name="confirmpwd"
                            ref={rConfirmNewPassword}
                            required
                            value={confirmpwd}
                            tooltip="Confirmed password MUST match new password"
                            placeholder="Confirm new password here">
                            Confirm New Password
                        </AbdsInput>

                    </div>
                    <p data-role="profilegroup">
                        <AbdsButton name="cancelsettings" onClick={onCancelSettings} appearance="outline" palette="brand">Cancel</AbdsButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <AbdsButton name="savesettings" ref={rSubmitButton} onClick={onClickSaveSettings}>Update Account Settings</AbdsButton>
                    </p>
                </div>
            </div>
        </div>
    );
}
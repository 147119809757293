import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import createUserSessionActivity from '../utilities/usersessiontracking';
import { createHttpClient } from '../utilities/httpclient';
import LocalStorageController from '../utilities/lsc';
import { APPLICATION_API_ENDPOINT, APP_AUTH_TOKEN } from '../utilities/constants';

export function Logout()
{
    const navigate = useNavigate();

    const executeLogout = () => {
        createUserSessionActivity(window.location.pathname);
        const httpclient = createHttpClient();
        httpclient.post(`${APPLICATION_API_ENDPOINT}/systemaccess/logout`).then(() => {
            const lsc = new LocalStorageController();
            lsc.remove(APP_AUTH_TOKEN);
            navigate('/', { replace: true });
        });
    };

    useEffect(() => executeLogout(), []);

    return (<></>);
}
import { React, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createHttpClient, handleRemoteError } from '../utilities/httpclient';
import { AbdsButton, AbdsLabel, AbdsSelect, AbdsSelectOption, AbdsInput } from '@abds/react-bindings';
import CertificateTypeDisplayList from './auxiliary/CertificateTypeDisplayList';
import ExpirationEventMetadataTable from './auxiliary/ExpirationEventMetadataTable';
import NewCertificateLookupModal from './modals/NewCertificateLookupModal';
import NewExpirationEventEmailRequestModal from './modals/NewExpirationEventEmailRequestModal';

import DatePicker from 'react-datepicker';
import { formatDate } from '../utilities/general';

import momentJS from 'moment'; //will need this to parse dates from database to populate DatePicker component
import createUserSessionActivity from '../utilities/usersessiontracking';
import { createDisplayAreaMessengerContext, DisplayAreaMessenger } from './displayareamessenger/DisplayAreaMessenger';
import DeleteExpirationEventPartnerConfirmationModal from './modals/DeleteExpirationEventConfirmationModal';
import { APPLICATION_API_ENDPOINT } from '../utilities/constants';
import { useKeyPressEnter } from '../customhooks/useKeyPressEnter';
import DeleteNewCertificateConfirmationModal from './modals/DeleteNewCertificateConfirmationModal';

import "react-datepicker/dist/react-datepicker.css";
import '../styles/absgtable.css';
import '../styles/certificatedetails.css';

export function CertificateManagementDetails(props) {
    let emptyCertificateDetailState = {
        certificateID: 0,
        communityID: 0
    };
    let [expirationEventGUID, setExpirationEventGUID] = useState(null);
    let [communityID, setCommunityID] = useState(-1);
    let [certificateID, setCertificateID] = useState(-1);
    let [hasBeenCreated, setHasBeenCreated] = useState(false);
    let [isEditable, setIsEditable] = useState(true);
    let [associatedExpirationEventMetadata, setAssociatedExpirationEventMetadata] = useState(null);
    let [communityName, setCommunityName] = useState('');
    let [defaultRoutingID, setDefaultRoutingID] = useState('');
    let [certificateActivationDateTime, setCertificateActivationDateTime] = useState(new Date());
    let [tmpActivationDate, setTmpActivationDate] = useState(new Date());
    let [expiringCertificateDetails, setExpiringCertificateDetails] = useState(null);
    let [newCertificateDetails, setNewCertificateDetails] = useState(null);
    let [businessUnit, setBusinessUnit] = useState('');
    let [businessUnitOther, setBusinessUnitOther] = useState('');
    let [businessUnitList, setBusinessUnitList] = useState([]);
    let [selectedNotificationDate, setSelectedNotificationDate] = useState(new Date());
    let [tmpSelectedNotificationDate, setTmpSelectedNotificationDate] = useState(new Date());
    let [expiringCertificateID, setExpiringCertificateID] = useState(null);
    let [renewCertificateID, setRenewCertificateID] = useState(null);
    let [currentExpirationEventStatusKey, setCurrentExpirationEventStatusKey] = useState(-1);
    let [showModal, setShowModal] = useState(false);
    let [notificationDateList, setNotificationDateList] = useState([]);
    let [notificationType, setNotificationType] = useState(-1);
    let [messageContext, setMessageContext] = useState({});

    let [showMissingNotificationTypeError, setShowMissingNotificationTypeError] = useState(false);
    let [showMissingNotificationDateError, setShowMissingNotificationDateError] = useState(false);

    let rBusinessUnits = useRef(null);
    let rNotificationType = useRef(null);
    let rBusinessUnitOther = useRef(null);
    let rSubmitButton = useRef(null);
    let rAddNewNotificationDataSchedule = useRef(null);
    let rSubmitButtonNewCertificateModal = useRef(null);
    let rSubmitButtonEmailRequestModal = useRef(null);

    const navigate = useNavigate();

    const params = useParams();

    const dateNoTimeFormat = "MM/dd/yyyy";

    const getBusinessUnits = async () => {
        const httpclient = createHttpClient();
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/certificate/businessunits`).then(response => {
            setBusinessUnitList(response?.data || []);
        });
    };

    const isClosed = () => +currentExpirationEventStatusKey === 6;

    const showErrorMessage = msg => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        messageContext.error.message = msg;
        setMessageContext(messageContext);
    };

    const getCertificateDetailsByCommunity = async (communityKey, certificateKey, expirationEventGUID) => {
        const httpclient = createHttpClient();
        const criteria = {
            'CommunityID': +communityKey,
            'CertificateID': +certificateKey,
            'ExpirationEventGUID': expirationEventGUID
        };
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/certificate/detailsbykey`, criteria).then(response => {
            setHasBeenCreated(!!(response?.data?.hasBeenCreated ?? false));
            setIsEditable(!!(response?.data?.isEditable ?? true));
            setAssociatedExpirationEventMetadata(response?.data?.associatedData);
            setExpirationEventGUID(response?.data?.associatedData?.expirationEventGUID);
            setCurrentExpirationEventStatusKey((response?.data?.associatedData?.statusKey) || -1);
            setCertificateActivationDateTime(formatDate(response?.data.associatedData?.expirationEvent?.activationDateLocal));

            if (response?.data?.expirationEventNotificationSchedules) {
                const mappedDates = Array.from(response.data.expirationEventNotificationSchedules)
                    .map(scheduleDate => {
                        scheduleDate.notificationDateAsString = scheduleDate.notificationDateLocal;
                        scheduleDate.applicationTypeLabel = scheduleDate.applicationTypeLabel?.replace(/([a-z])([A-Z])/g, "$1 $2");
                        return scheduleDate;
                    });
                setNotificationDateList(mappedDates);
            }

            const theBusinessUnitValue = +response?.data?.associatedData?.expirationEvent?.applicationTypeKey;
            const theBusinessUnitOtherText = response?.data?.associatedData?.expirationEvent?.businessUnit;
            setBusinessUnit(theBusinessUnitValue);
            setBusinessUnitOther(theBusinessUnitValue === 5
                ? theBusinessUnitOtherText
                : '');
            setCommunityID(+(response?.data?.communityID));
            setCommunityName(response?.data.communityName);
            setDefaultRoutingID(response?.data?.defaultRoutingID);
            setExpiringCertificateDetails(response?.data?.expired || emptyCertificateDetailState);
            setNewCertificateDetails(response?.data?.newcerts || emptyCertificateDetailState);
            setExpiringCertificateID(!!response?.data?.expired?.certificateID
                ? +response?.data?.expired?.certificateID
                : -1);
            setRenewCertificateID(!!response?.data?.newcerts?.certificateID
                ? +response.data.newcerts.certificateID
                : -1);
        })
            .catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    const onChangeBusinessUnit = e => setBusinessUnit(+e.target.value);

    const onChangeBusinessUnitOther = e => setBusinessUnitOther(e.target.value);

    const onClickStartRenewalProcess = e => {
        e.preventDefault();
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        let validated = true;

        if (!newCertificateDetails || newCertificateDetails?.length === 0) {
            messageContext.error.details.push("A new certificate is required");
            validated = false;
        }

        if (!momentJS(certificateActivationDateTime).isValid()) {
            messageContext.error.details.push("Activation date is required");
            validated = false;
        }

        if (notificationDateList.length == 0) {
            messageContext.error.details.push("At least one notification date is required");
            validated = false;
        }

        if (!businessUnit || businessUnit?.length === 0) {
            messageContext.error.details.push("Business unit is required");
            validated = false;
        }
        else if (+businessUnit === 5 && (!businessUnitOther || businessUnitOther?.length === 0)) {
            messageContext.error.details.push("Please enter your business unit if it is not provided in the drop-down list");
            validated = false;
        }

        if (messageContext.error.details.length > 0)
            messageContext.error.message = "Errors were found in processing:";

        if (!validated) {
            setMessageContext(messageContext);
            return;
        }

        const httpclient = createHttpClient();
        let formattedDatesSchedules = notificationDateList;
        formattedDatesSchedules.map(item => {
            item.notificationDate = null;
            return item;
        });

        const criteria = {
            'ExpirationEventGUID': expirationEventGUID,
            'CommunityID': communityID,
            'BusinessUnitID': +businessUnit,
            'ExpiringCertificateID': expiringCertificateID,
            'RawActivationDate': certificateActivationDateTime,
            'ActivationDate': null,
            'RenewCertificateID': renewCertificateID,
            'BusinessUnitName': (+businessUnit === 5) ? businessUnitOther : '', //remove name if user did not choose 'Other'
            'ExpirationEventNotificationSchedules': formattedDatesSchedules
        };

        createUserSessionActivity(window.location.pathname, 'onClickStartRenewalProcess', criteria);
        httpclient.post(`${APPLICATION_API_ENDPOINT}/certificate/startrenewal`, criteria).then(response => {
            if (!!response?.data?.success) {
                messageContext.success.message = response.data.message;
                setAssociatedExpirationEventMetadata(response.data.metadata);

                if (!hasBeenCreated)
                    setHasBeenCreated(true);

                if (!!response?.data?.metadata?.expirationEventGUID)
                    setExpirationEventGUID(response.data.metadata.expirationEventGUID);
            }
            else if (!!response?.data?.warning) {
                messageContext.warning.message = response.data.message;
            }
            else {
                messageContext.error.message = response.data.message;
            }
            window.scrollTo(0, 0);
            setMessageContext(messageContext);
        })
            .catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    const onClickDownloadPartnerActivity = e => {
        e.preventDefault();
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        const httpclient = createHttpClient();
        const criteria = {
            'CommunityID': communityID,
            'CertificateID': certificateID,
            'ExpirationEventGUID': expirationEventGUID
        };
        createUserSessionActivity(window.location.pathname, 'onClickDownloadPartnerActivity', criteria);
        httpclient.post(`${APPLICATION_API_ENDPOINT}/certificate/downloadpartnerlist`, criteria).then(response => {
            const isValidResponse = (r => {
                return !!r && !!r.data &&
                    r.data.hasOwnProperty('fileContents') &&
                    r.data.hasOwnProperty('contentType') &&
                    r.data.hasOwnProperty('fileDownloadName');
            })(response);

            if (!isValidResponse) {
                showErrorMessage('There was a problem fetching your requested export');
                return;
            }
            const hl = document.createElement('a');
            hl.href = `data:application/octet-stream;base64,${response.data.fileContents}`;
            hl.target = '_blank';
            hl.download = response.data.fileDownloadName;
            hl.click();
        })
            .catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    const onClickDownloadPartnerList = e => {
        e.preventDefault();
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        const httpclient = createHttpClient();
        const criteria = {
            'CommunityID': communityID,
            'CertificateID': certificateID,
            'ExpirationEventGUID': expirationEventGUID
        };
        createUserSessionActivity(window.location.pathname, 'onClickDownloadPartnerList', criteria);
        httpclient.post(`${APPLICATION_API_ENDPOINT}/certificate/downloadpartnertokenlist`, criteria).then(response => {
            const isValidResponse = (r => {
                return !!r && !!r.data &&
                    r.data.hasOwnProperty('fileContents') &&
                    r.data.hasOwnProperty('contentType') &&
                    r.data.hasOwnProperty('fileDownloadName');
            })(response);

            if (!isValidResponse) {
                showErrorMessage('There was a problem fetching your requested export');
                return;
            }
            const hl = document.createElement('a');
            hl.href = `data:application/octet-stream;base64,${response.data.fileContents}`;
            hl.target = '_blank';
            hl.download = response.data.fileDownloadName;
            hl.click();
        })
            .catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    const removeNewCertificate = e => {
        e.preventDefault();
        setNewCertificateDetails(emptyCertificateDetailState);
    };

    const onClickGoToExpirationEventPartners = e => {
        e.preventDefault();

        if (!expirationEventGUID)
            return;

        navigate(`/expirationeventpartners/${expirationEventGUID}`);
        return;
    };

    const handleSelectedNewCertificate = value => {
        setRenewCertificateID(+(value?.certificateID || -1));
        value.remoteURLs = expiringCertificateDetails.remoteURLs;
        setNewCertificateDetails(value);
    };

    const onClickRemoveNotificationDateSchedule = (e, selectedItem) => {
        e.preventDefault();

        if (!selectedItem)
            return;

        const newDateSchedule = notificationDateList.filter(item => +item.key !== +selectedItem.key);
        setNotificationDateList(newDateSchedule);

        //if expirationEventGUID is not Guid.Empty AND selectedItem.expirationEventGUID is not Guid.Empty, log delete
    };

    const getSaveButtonText = () => (hasBeenCreated ? 'Update' : 'Start') + ' Renewal Process';

    const generateRandomInteger = () => Math.floor(Math.random() * 9e9);

    const onClickAddNewNotificationDateSchedule = e => {
        e?.isDefaultPrevented?.() || e?.preventDefault?.();
        setShowMissingNotificationDateError(false);
        setShowMissingNotificationTypeError(false);
        const value = +rNotificationType.current.value;
        let typeCheck = true;
        let dateCheck = true;

        if (isNaN(+value) || ![40, 41, 42, 43].includes(+value)) {
            setShowMissingNotificationTypeError(true);
            typeCheck = false;
        }

        if (!momentJS(selectedNotificationDate).isValid()) {
            setShowMissingNotificationDateError(true);
            dateCheck = false;
        }

        if (!typeCheck || !dateCheck)
            return;

        setNotificationType(value);
        const label = document.querySelector(`[data-role="notificationtypes"] select > option[value="${value}"]`)?.innerHTML;
        let tmpDateList = [...notificationDateList, {
            key: generateRandomInteger(),
            expirationEventNotificationScheduleGuid: '00000000-0000-0000-0000-000000000000',
            expirationEventGuid: expirationEventGUID ?? '00000000-0000-0000-0000-000000000000',
            applicationTypeKey: value,
            notificationDate: selectedNotificationDate,
            notificationDateAsString: selectedNotificationDate,
            isProcessed: false,
            applicationTypeLabel: label
        }];
        setNotificationDateList(tmpDateList);

        //if expirationEventGUID is NOT Guid.Empty, log change

        setSelectedNotificationDate('');
        setNotificationType('');
    };

    const sortNotificationList = list => list.sort((dateA, dateB) => {
        const B = new Date(dateB.notificationDate);
        const A = new Date(dateA.notificationDate);
        return A.getTime() - B.getTime();
    });

    const validForDeletion = (item) => {
        if (!item)
            return true; //true is default value from useState

        const validDate = momentJS().isBefore(new Date(item.notificationDate?.toLocaleString("en-US")));
        return (!!validDate && item.isProcessed !== true && isEditable) || currentExpirationEventStatusKey <= 2; //new or open EE status
    };

    useKeyPressEnter(rSubmitButton, e => {
        if (!!document.querySelector('abds-modal[name="newcertificatemodal"]')?.open) {
            rSubmitButtonNewCertificateModal.current?.click(e);
        }
        else if (!!document.querySelector('abds-modal[name="emailmodal"]')?.open) {
            rSubmitButtonEmailRequestModal.current?.click(e);
        }
        else {
            rSubmitButton.current?.click(e);
        }
    });

    useEffect(() => {
        notificationDateList = sortNotificationList(notificationDateList);
    }, [notificationDateList]);

    useEffect(() => {
        createUserSessionActivity(window.location.pathname);
        getBusinessUnits();
        const communityKey = isNaN(params.communityID) ? 0 : parseInt(params.communityID);
        const certificateKey = isNaN(params.certificateID) ? 0 : parseInt(params.certificateID);
        const expirationEventGUID = params.expirationEventGUID;
        setCertificateID(certificateKey);
        setCommunityID(communityKey);
        getCertificateDetailsByCommunity(communityKey, certificateKey, expirationEventGUID);
    }, []);

    useEffect(() => {
        rBusinessUnits.current?.addEventListener('abdsChange', onChangeBusinessUnit);
        rBusinessUnitOther.current?.addEventListener('abdsInput', onChangeBusinessUnitOther);

        return () => {
            rBusinessUnits.current?.removeEventListener('abdsChange', onChangeBusinessUnit);
            rBusinessUnitOther.current?.removeEventListener('abdsInput', onChangeBusinessUnitOther);
        };
    }, [rBusinessUnits, rBusinessUnitOther]);

    return (
        <div>
            <h3>Certificate Management: Expiration Event</h3>

            <hr className="abcblue" />

            <DisplayAreaMessenger context={messageContext} />

            <div data-role="detailheader">
                <div>
                    <div data-role="group">
                        <AbdsLabel>Community Name:</AbdsLabel>
                        <div>{communityName}</div>
                    </div>
                </div>
                <div>
                    <div data-role="group">
                        <AbdsLabel>AS2 ID:</AbdsLabel>
                        <div>{defaultRoutingID}</div>
                    </div>
                </div>
                <div>
                    {
                        hasBeenCreated &&
                        <div>
                            <div data-role="group">
                                <AbdsLabel>&nbsp;</AbdsLabel>
                                <div>
                                    <a href="#" onClick={onClickDownloadPartnerActivity} title="Download Partner Activity">
                                        <span className="bi bi-download"></span>&nbsp;
                                        Download Partner Activity
                                    </a>
                                </div>
                                <div>
                                    <a href="#" onClick={onClickDownloadPartnerList} title="Download Partner List">
                                        <span className="bi bi-download"></span>&nbsp;
                                        Download Partner List
                                    </a>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {
                hasBeenCreated &&
                associatedExpirationEventMetadata != null &&
                <ExpirationEventMetadataTable {...associatedExpirationEventMetadata} />
            }
            {
                hasBeenCreated &&
                <>
                    <p>
                        <AbdsButton onClick={onClickGoToExpirationEventPartners}>
                            Modify Expiration Event Partners
                        </AbdsButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {
                            isEditable &&
                            <>
                                <DeleteExpirationEventPartnerConfirmationModal
                                    expirationEventGUID={expirationEventGUID}
                                    showModal={showModal} />
                            </>
                        }
                    </p>
                    <hr className="abcblue" />
                </>
            }

            <h4>Expiring Certificate Details</h4>
            {
                expiringCertificateDetails === null &&
                (<h3 className="center">Loading expiring certificate data...</h3>)
            }
            {
                expiringCertificateDetails != null &&
                +expiringCertificateDetails?.communityID === 0 &&
                +expiringCertificateDetails?.certificateID === 0 &&
                (<h3 className="center">No certificate data</h3>)
            }
            {
                expiringCertificateDetails != null &&
                +expiringCertificateDetails?.certificateID > 0 &&
                +expiringCertificateDetails?.communityID > 0 &&
                <>
                    <table className="absgtable auxiliary">
                        <thead>
                            <tr>
                                <th>Certificate Name:</th>
                                <th>Serial Number:</th>
                                <th>Begin Date:</th>
                                <th>End Date:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{expiringCertificateDetails.certificateName}</td>
                                <td>{expiringCertificateDetails.serialNumber}</td>
                                <td>{expiringCertificateDetails.beginDateDisplay}</td>
                                <td>{expiringCertificateDetails.endDateDisplay}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="absgtable auxiliary urltable">
                        <thead>
                            <tr>
                                <th>Type:</th>
                                <th colSpan="4">AS2 URL:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                +(expiringCertificateDetails?.remoteURLs?.length || 0) > 0 &&
                                expiringCertificateDetails.remoteURLs.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><CertificateTypeDisplayList items={item.certificateTypeDisplayItems} /></td>
                                            <td colSpan="3">{item.remoteURI}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </>
            }

            <hr className="abcblue" />

            <h4><span className="required">*</span>New Certificate Details</h4>
            {
                newCertificateDetails === null &&
                (<h3 className="center">Loading new certificate data...</h3>)
            }
            {
                newCertificateDetails != null &&
                +newCertificateDetails?.communityID === 0 &&
                +newCertificateDetails?.certificateID === 0 &&
                (<NewCertificateLookupModal
                    showAddButton={(!newCertificateDetails || (+newCertificateDetails.communityID > 0 && +newCertificateDetails.certificateID > 0))}
                    modalSubmitButton={rSubmitButtonNewCertificateModal}
                    communityID={communityID}
                    certificateID={certificateID}
                    handleShowErrorMessage={showErrorMessage}
                    handleSelectedNewCertificate={handleSelectedNewCertificate}
                />)
            }
            {
                newCertificateDetails != null &&
                +newCertificateDetails?.certificateID > 0 &&
                +newCertificateDetails?.communityID > 0 &&
                (<>
                    <table className="absgtable auxiliary">
                        <thead>
                            <tr>
                                <th>Certificate Name:</th>
                                <th>Serial Number:</th>
                                <th>Begin Date:</th>
                                <th>End Date:</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{newCertificateDetails.certificateName}</td>
                                <td>{newCertificateDetails.serialNumber}</td>
                                <td>{newCertificateDetails.beginDateDisplay}</td>
                                <td>{newCertificateDetails.endDateDisplay}</td>
                                <td className="centered">
                                    {
                                        !hasBeenCreated &&
                                        (
                                            <DeleteNewCertificateConfirmationModal
                                                certificateDetails={newCertificateDetails}
                                                funcproperty={removeNewCertificate} />
                                        )
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="absgtable auxiliary urltable">
                        <thead>
                            <tr>
                                <th>Type:</th>
                                <th colSpan="4">AS2 URL:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                +(newCertificateDetails?.remoteURLs?.length || 0) > 0 &&
                                newCertificateDetails.remoteURLs.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><CertificateTypeDisplayList items={item.certificateTypeDisplayItems} /></td>
                                            <td colSpan="3">{item.remoteURI}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </>)
            }

            <hr className="abcblue" />

            <h4>Notification Schedule</h4>
            <div className="note"><strong>NOTE:</strong> Schedule dates are <strong>NOT</strong> saved until you click on the "{getSaveButtonText()}" button below</div>

            <table className="renewalfunctions">
                <tbody>
                    <tr>
                        <td className={expirationEventGUID ? 'threecolumn' : 'twocolumn'}>
                            <div data-role="group">
                                {notificationDateList.length > 0 && (
                                    <table className="absgtable">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Label</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortNotificationList(notificationDateList).map(item => {
                                                return (
                                                    <tr key={item.key}>
                                                        <td>{formatDate(item.notificationDateAsString).toString()}</td>
                                                        <td>{item.applicationTypeLabel}</td>
                                                        <td className="centered">
                                                            {
                                                                validForDeletion(item) &&
                                                                (
                                                                    <a
                                                                        href="#"
                                                                        title="click to remove this date"
                                                                        onClick={e => onClickRemoveNotificationDateSchedule(e, item)}>
                                                                        <i className="bi bi-trash"></i>
                                                                    </a>
                                                                )
                                                            }
                                                            {
                                                                !isEditable && (<>&nbsp;</>)
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                )}
                                {notificationDateList.length === 0 && (
                                    <p className="centered instruction">Select a notification type and date to the right to add to this expiration event.</p>
                                )}
                            </div>
                        </td>
                        <td className={expirationEventGUID ? 'threecolumn' : 'twocolumn'}>

                            <div data-role="group">
                                <span className="required">*</span>
                                <AbdsLabel>Notification Type</AbdsLabel>
                                <div>
                                    <div className="certificatedetails" data-role="notificationtypes">
                                        <AbdsSelect
                                            name="notificationType"
                                            ref={rNotificationType}
                                            value={notificationType}
                                            width="full"
                                            placeholder="-- Choose Notification Type --">
                                            <AbdsSelectOption key={40} value={40}>Expiration Notification (No Link)</AbdsSelectOption>
                                            <AbdsSelectOption key={41} value={41}>Renewal Notification (With Link)</AbdsSelectOption>
                                            <AbdsSelectOption key={42} value={42}>Final Notification (With Link)</AbdsSelectOption>
                                            <AbdsSelectOption key={43} value={43}>Install Notification (With Link)</AbdsSelectOption>
                                        </AbdsSelect>
                                    </div>
                                    {showMissingNotificationTypeError && (
                                        <div className="errorMessage">Type is required.</div>
                                    )}
                                </div>
                            </div>

                            <div data-role="group">
                                <span className="required">*</span>
                                <AbdsLabel>Notification email date</AbdsLabel>
                                <div>
                                    <DatePicker
                                        disabled={isClosed()}
                                        isClearable={true}
                                        value={selectedNotificationDate}
                                        onSelect={date => {
                                            const correctedDate = momentJS(date, dateNoTimeFormat);
                                            setTmpSelectedNotificationDate(correctedDate.toDate());
                                            setSelectedNotificationDate(correctedDate.toDate().toLocaleString("en-US"));
                                        }}
                                        onChange={date => {
                                            const correctedDate = momentJS(tmpSelectedNotificationDate, dateNoTimeFormat)
                                                .hours(date.getHours())
                                                .minutes(date.getMinutes());
                                            setSelectedNotificationDate(correctedDate.toDate().toLocaleString("en-US"));
                                        }}
                                        minDate={new Date()}
                                        timeIntervals={10}
                                        timeCaption="Time:"
                                        timeFormat="hh:mm aa"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeSelect
                                        className="certificatedetails"
                                    />
                                </div>
                                {showMissingNotificationDateError && (
                                    <div className="errorMessage">Date is required.</div>
                                )}
                            </div>

                            <div data-role="group">
                                <AbdsButton
                                    onClick={onClickAddNewNotificationDateSchedule}
                                    ref={rAddNewNotificationDataSchedule}
                                    disabled={isClosed()}
                                >
                                    Add
                                </AbdsButton>
                            </div>
                        </td>
                        {!!expirationEventGUID && (
                            <td className="expirationemailcell threecolumn">
                                <div data-role="group">
                                    <NewExpirationEventEmailRequestModal
                                        modalSubmitButton={rSubmitButtonEmailRequestModal}
                                        expirationEventGUID={expirationEventGUID}
                                    />
                                </div>
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td>
                            <div data-role="group">
                                <span className="required">*</span>
                                <AbdsLabel>Activation Date</AbdsLabel>
                                <div>

                                    <DatePicker
                                        disabled={isClosed()}
                                        isClearable={true}
                                        minDate={new Date()}
                                        value={certificateActivationDateTime}
                                        onSelect={date => {
                                            const correctedDate = momentJS(date, dateNoTimeFormat);
                                            setTmpActivationDate(correctedDate.toDate());
                                            setCertificateActivationDateTime(correctedDate.toDate().toLocaleString("en-US"));
                                        }}
                                        onChange={date => {
                                            const correctedDate = momentJS(tmpActivationDate, dateNoTimeFormat)
                                                .hours(date.getHours())
                                                .minutes(date.getMinutes());
                                            setCertificateActivationDateTime(correctedDate.toDate().toLocaleString("en-US"));
                                        }}
                                        timeIntervals={10}
                                        timeCaption="Time:"
                                        timeFormat="hh:mm aa"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeSelect
                                        className="certificatedetails"
                                    />

                                </div>
                            </div>
                        </td>
                        <td>
                            <div data-role="group">
                                <span className="required">*</span>
                                <AbdsLabel>Business Unit</AbdsLabel>
                                <div>
                                    <div className="certificatedetails">
                                        <AbdsSelect
                                            name="businessunits"
                                            ref={rBusinessUnits}
                                            value={businessUnit}
                                            width="full"
                                            placeholder="-- Choose Business Unit --">
                                            {
                                                businessUnitList?.map(item => {
                                                    return (
                                                        <AbdsSelectOption
                                                            key={item.applicationTypeKey}
                                                            value={item.applicationTypeKey}
                                                            selected={+businessUnit === +item.applicationTypeKey}>
                                                            {item.label}
                                                        </AbdsSelectOption>
                                                    );
                                                })
                                            }
                                        </AbdsSelect>
                                    </div>
                                    <div>
                                        <div className="certificatedetails">
                                            <AbdsInput
                                                style={{ 'display': +businessUnit === 5 ? 'block' : 'none' }}
                                                type="text"
                                                width="full"
                                                name="otherBusinessUnit"
                                                ref={rBusinessUnitOther}
                                                value={businessUnitOther}
                                                tooltip="Enter Business Unit Name"
                                                placeholder="Enter Business Unit Name">
                                                Enter Business Unit Name
                                            </AbdsInput>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan="3">
                            <div>
                                <AbdsButton onClick={onClickStartRenewalProcess} ref={rSubmitButton}>
                                    {getSaveButtonText()}
                                </AbdsButton>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
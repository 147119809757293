import { React, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AbdsButton } from '@abds/react-bindings';
import { createHttpClient, handleRemoteError } from '../../utilities/httpclient';
import createUserSessionActivity from '../../utilities/usersessiontracking';
import { createDisplayAreaMessengerContext, DisplayAreaMessenger } from '../displayareamessenger/DisplayAreaMessenger';
import { APPLICATION_API_ENDPOINT, checkUserTokenFormat } from '../../utilities/constants';
import { formatDate } from '../../utilities/general';

import '../../styles/main.css';
import '../../styles/searchquerycontainer.css';
import '../../styles/absgtable.css';
import '../../styles/certificatedetails.css';

export function X12Details(props) {
    let [messageContext, setMessageContext] = useState(createDisplayAreaMessengerContext());
    let [headerData, setHeaderData] = useState({});
    let [messageData, setMessageData] = useState([]);
    let [statusData, setStatusData] = useState({});

    const params = useParams();

    const navigate = useNavigate();

    const showErrorMessage = msg => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        messageContext.error.message = msg;
        setMessageContext(messageContext);
    };

    const handleDashboardButtonOnClick = () => {
        navigate('/x12'); //return to dashboard
        return;
    };

    const getX12CaseMessageDataByKey = async key => {
        if (!checkUserTokenFormat(key))
            handleDashboardButtonOnClick();

        const httpclient = createHttpClient();
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/x12/getcase`, { 'X12CaseGUIDAsString': key }).then(response => {
            setHeaderData(response.data.x12CaseHeader);
            setStatusData(response.data.x12CaseStatus);
            setMessageData(response.data.x12CaseMessageDetails);
        }).catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    useEffect(() => {
        (async () => {
            createUserSessionActivity(window.location.pathname);
            await getX12CaseMessageDataByKey(params.x12CaseGUID);
        })();
    }, []);

    return (
        <div>
            <h3>X12 Validator: Case Details</h3>
            <hr className="abcblue" />

            <DisplayAreaMessenger context={messageContext} />

            <h4>Case Details</h4>

            {
                (!headerData || Object.keys(headerData).length === 0) && (
                    <p className="centered">No header data</p>
                )
            }
            {
                (Object.keys(headerData).length > 0) && (
                    <>
                        <div className="rightside">
                            <AbdsButton onClick={handleDashboardButtonOnClick}>Return to X12 Dashboard</AbdsButton>
                        </div>

                        <hr className="abcblue" />

                        <table data-role="subdetailheader">
                            <thead>
                                <tr>
                                    <th>Case Name:</th>
                                    <th>RITM:</th>
                                    <th>Status:</th>
                                    <th>Created:</th>
                                    <th>Processed:</th>
                                    <th>Error:</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{headerData.caseName}</td>
                                    <td>{headerData.ritm}</td>
                                    <td>{statusData.label}</td>
                                    <td>{formatDate(headerData.processedDateTime)}</td>
                                    <td>{formatDate(headerData.createdByDateTime)}</td>
                                    <td>
                                        {
                                            (headerData.errorMessage?.length > 0) &&
                                            (
                                                <>{headerData.errorMessage}</>
                                            )
                                        }
                                        {
                                            (!headerData.errorMessage || headerData.errorMessage.length === 0) &&
                                            (
                                                <>(none)</>
                                            )
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                )
            }

            {
                (messageData?.length === 0) && (
                    <>
                        <hr className="abcblue" />
                        <p className="centered">No message details</p>
                    </>
                )
            }

            {
                !!messageData && messageData.length > 0 && (
                    <>
                        <hr className="abcblue" />
                        <table className="absgtable auxiliary">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Source File</th>
                                    <th>Compare File</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    messageData.map(item => {
                                        return (
                                            <tr>
                                                <td>
                                                    {
                                                        item.errored && (
                                                            <>
                                                                <span className="bi bi-x-octagon-fill crimson"></span>
                                                                <span className="statuslabel crimson">{item.applicationTypeLabel.toUpperCase()}</span>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        item.failed && (
                                                            <>
                                                                <span className=" bi bi-x-square-fill redorange"></span>
                                                                <span className="statuslabel redorange">{item.applicationTypeLabel.toUpperCase()}</span>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        item.success && (
                                                            <>
                                                                <span className="bi bi-check-circle-fill green"></span>
                                                                <span className="statuslabel green">{item.applicationTypeLabel.toUpperCase()}</span>
                                                            </>
                                                        )
                                                    }
                                                </td>
                                                <td>{item.sourceFileName}</td>
                                                <td>{item.compareFileName}</td>
                                                <td>{item.message}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                        <hr className="abcblue" />
                        <div className="rightside">
                            <AbdsButton onClick={handleDashboardButtonOnClick}>Return to X12 Dashboard</AbdsButton>
                        </div>
                    </>
                )
            }
        </div>
    );
}
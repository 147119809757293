import { React, useState, useEffect, useRef } from 'react';
import { createHttpClient } from '../utilities/httpclient';
import { NavItem } from 'reactstrap';
import { AbdsButton } from '@abds/react-bindings';
import LocalStorageController from '../utilities/lsc';
import {
    URL_ABOUT_US, URL_PRIVACY_STATEMENT, URL_TERMS_CONDITIONS,
    COPYRIGHT, APP_NAME, COMPANY_NAME, USE_CENCORA_BRANDING,
    APPLICATION_API_ENDPOINT, APP_AUTH_TOKEN
} from '../utilities/constants';
import { createDisplayAreaMessengerContext, DisplayAreaMessenger } from './displayareamessenger/DisplayAreaMessenger';
import { useKeyPressEnter } from '../customhooks/useKeyPressEnter';

import '../styles/home.css';

export function Home() {
    const topLeftLogoImageCssClass = (!!USE_CENCORA_BRANDING)
        ? "cencora"
        : "abc";

    let [messageContext, setMessageContext] = useState({});
    const rSubmitButton = useRef(null);

    const showErrorMessage = msg => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        messageContext.error.message = msg;
        setMessageContext(messageContext);
    };

    const processSsoLoginRequest = () => {
        setMessageContext({});
        const httpclient = createHttpClient();
        httpclient.post(`${APPLICATION_API_ENDPOINT}/systemaccess/ssologin`).then(response => {
            if (!response?.data) {
                showErrorMessage('There was a problem with the server.  Please try again later.');
                return;
            }

            if (response.data) {
                window.parent.location.href = response.data;
            }
            else {
                showErrorMessage('Please try again.');
            }
        })
            .catch(e => showErrorMessage(e.response?.data || e.message));
    };

    const onClickAttemptLogin = async e => {
        const target = document.querySelector('body');
        (target.classList.contains('login') || target.classList.add('login'));
        const lsc = new LocalStorageController();
        lsc.remove(APP_AUTH_TOKEN);
        processSsoLoginRequest();

        return () => {
            const target = document.querySelector('body');
            target?.classList.remove('login');
        };
    };

    useKeyPressEnter(rSubmitButton);

    useEffect(() => {
        let target = document.querySelector('body');
        (target.classList.contains('login') || target.classList.add('login'));

        if (window.location.search.includes("error")) {
            let errorCode = -1;
            let message = '';

            if (window.location.search?.length > 0) {
                const getErrorCode = window.location.search.split('=');
                errorCode = getErrorCode[1] ?? -1;
                const lsc = new LocalStorageController();
                message = lsc.getData(errorCode);
            }

            if (message?.length > 0) {
                messageContext = createDisplayAreaMessengerContext();
                messageContext.error.message = message;
                setMessageContext(messageContext);
            }
        }

        return () => {
            let target = document.querySelector('body');
            target.classList.remove('login');
        };
    }, []);

    return (
        <div className="container my-4">
            <div className="p-3 mb-4">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="p-2 bd-highlight col-example">
                        <div data-role="companylogo" className={topLeftLogoImageCssClass}>
                            <h4>{COMPANY_NAME}</h4>
                        </div>
                        <section id="logincontainer">
                            <DisplayAreaMessenger context={messageContext} />
                            <div data-role="login">
                                <h1>my<b>EDI</b></h1>

                                <hr />

                                <div>
                                    <h3>Log In</h3>
                                    <hr />
                                    <div data-role="formgroup">
                                        <div className="centered">

                                            <AbdsButton
                                                ref={rSubmitButton}
                                                onClick={onClickAttemptLogin}
                                                className="float-right">
                                                Login via SSO
                                            </AbdsButton>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div data-role="loginfooter">
                                <ul className="navbar-nav flex-grow flex-row d-flex align-items-center justify-content-center">
                                    <NavItem>
                                        <a target="_blank" href={URL_ABOUT_US}>About Us</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                    </NavItem>
                                    <NavItem>
                                        <a target="_blank" href={URL_TERMS_CONDITIONS}>Terms and Conditions</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                    </NavItem>
                                    <NavItem>
                                        <a target="_blank" href={URL_PRIVACY_STATEMENT}>Privacy Statement</a>
                                    </NavItem>
                                </ul>
                                <p>{COPYRIGHT}</p>
                                <div className="centered">{APP_NAME}</div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}
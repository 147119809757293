import { React, useState, useEffect } from 'react';
import { AbdsButton, AbdsModal } from '@abds/react-bindings';
export default function DeleteNewCertificateConfirmationModal(props)
{
    let [visible, setVisible] = useState(false);
    let [certificateDetails, setCertificateDetails] = useState(props.certificateDetails);
    const onClickShowDeleteConfirmationModal = e => {
        e.preventDefault();
        setVisible(true);
    };
    const onSubmitDeleteNewCertificate = e => {
        props.funcproperty?.(e);
        setVisible(false);
    };
    const onCancelEvent = e => {
        e.preventDefault();
        setVisible(false);
    };
    useEffect(() => {
        const modal = document.querySelector('abds-modal[name="confirmdeletenewcertmodal"]');
        modal?.setAttribute('open', !!visible);
    }, [visible]);
    return (
        <>
            {
                !visible && (
                    <a
                        data-commandargument={(certificateDetails || props.certificateDetails).certificateID}
                        href="#"
                        title="click to remove this certificate"
                        onClick={onClickShowDeleteConfirmationModal}>
                        <i className="bi bi-trash"></i>
                    </a>
                )
            }
            {
                visible &&
                (
                    <AbdsModal palette="danger" header-text="New Certificate Delete Confirmation" name="confirmdeletenewcertmodal">
                        <div slot="modal-body">
                            <p>Are you <b>SURE</b> you want to delete this certificate&nbsp;
                                <b>{props.certificateDetails.certificateName}</b>&nbsp;
                                from the community&nbsp;
                                <b>{props.certificateDetails.communityName}</b> from this expiration event?</p>
                        </div>
                        <div slot="modal-footer">
                            <AbdsButton appearance="outline" onClick={onCancelEvent}>
                                Cancel
                            </AbdsButton>
                            &nbsp;
                            <AbdsButton icon-start="remove" palette="destructive" onClick={onSubmitDeleteNewCertificate}>
                                DELETE
                            </AbdsButton>
                        </div>
                    </AbdsModal>
                )
            }
        </>
    );
}
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createHttpClient } from '../utilities/httpclient';
import LocalStorageController from '../utilities/lsc';
import { APPLICATION_API_ENDPOINT, APP_AUTH_TOKEN } from '../utilities/constants';

export function SsoResponseConnect()
{
    const navigate = useNavigate();
    const lsc = new LocalStorageController();
    const localStorageAsync = {
        set: (key, value) => Promise.resolve().then(() => lsc.save(key, value))
    };

    const handleErrorMessage = msg => {
        const lsc = new LocalStorageController();
        const rand = Math.floor(Math.random() * 99999999);
        lsc.save(rand, overridingMessage ?? ex.response?.data ?? ex.message);
        navigate(`/?error=${rand}`, { replace: true });
        return true;
    };

    useEffect(() => {
        const httpclient = createHttpClient();
        const payload = {
            Token: new URLSearchParams(window.location.search)?.get('code')
        };
        httpclient.post(`${APPLICATION_API_ENDPOINT}/systemaccess/oidclogin`, payload).then(response => {
            if (!response?.data) {
                handleErrorMessage('Invalid security response.  Please try again.');
            }
            else
            {
                localStorageAsync.set(APP_AUTH_TOKEN, response.data)
                    .then( () => navigate('/main', { replace: true }) )
            }
        })
        .catch(ex => handleErrorMessage(ex?.message));
    }, []);

    return (<></>);
}
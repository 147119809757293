const formatDate = datestring => {
    const dateobject = datestring
        ? new Date(datestring)
        : new Date();
    return new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour12: true,
        minute: '2-digit',
        hour: '2-digit',
        hourcycle: "h12",
    }).format(dateobject);
};

export { formatDate }; 
import axios from 'axios';
import LocalStorageController from './lsc';
import { APP_AUTH_TOKEN } from '../utilities/constants';

function createHttpClient()
{
    const lsc = new LocalStorageController();
    const tkn = lsc?.getData(APP_AUTH_TOKEN);
    const settings =
    {
        headers: (tkn?.toString().length > 0)
            ? { Authorization: `Bearer ${tkn}` }
            : {}
    };
    settings.headers['Content-Type'] = 'application/json; charset=UTF-8';
    settings.headers['Strict-Transport-Security'] = 'max-age=31536000; includeSubDomains';
    return axios?.create(settings);
}

function handleRemoteError (funcNavigate, ex, overridingMessage=null) //funcNavigate is the function created from useNavigate, which MUST be called within a component
{
    const isUnauthorized = +(ex?.response?.status) === 401;
    const noObjectInResponse = (ex?.response?.data) && typeof (ex?.response?.data) === 'string';

    if (isUnauthorized || noObjectInResponse)
    {
        if (ex.response.data.toLowerCase().includes('timed out'))
        {
            const lsc = new LocalStorageController();
            const rand = Math.floor(Math.random() * 99999999);
            lsc.save(rand, overridingMessage ?? ex.response?.data ?? ex.message);
            funcNavigate(`/?error=${rand}`, { replace: true });
            return true;
        }
    }

    return false;
}

export { createHttpClient, handleRemoteError };
import React from "react";

import '../../styles/multiplefileupload.css';

const X12MultipleFileUpload = ({
    files, filesHandler
}) => {
    const handleOnDrop = e => addSelectedFiles(e, e.dataTransfer.files);

    const handleAddFile = e => addSelectedFiles(e, e.target.files);

    const addSelectedFiles = (e, collection) => {
        e?.preventDefault();
        collection ??= [];

        if (collection.length === 0)
            return;

        const newFiles = Array.from(collection);
        filesHandler((prevFiles) => [...prevFiles, ...newFiles]);
        document.querySelector('section.x12fileuploadcontainer > div[data-role="dropzone"]')?.classList.remove('activedrag');
    };

    const onDragOverDropZone = e => {
        e.preventDefault();
        e.target.classList.add('activedrag');
    }

    const onDragLeaveDropZone = e => {
        e.preventDefault();
        e.target.classList.remove('activedrag');
    }

    const handleRemoveFile = index => filesHandler(prev => prev.filter((_, i) => i !== index));

    const handleBrowseFiles = e => {
        e?.preventDefault();
        document.getElementById('x12files')?.click();
    };

    return (
        <section className="x12fileuploadcontainer">
            <div data-role="dropzone" onDrop={handleOnDrop} onDragOver={onDragOverDropZone} onDragLeave={onDragLeaveDropZone}>
                <div className="uploadinstructions">Drag and drop files or <a href="#" onClick={handleBrowseFiles}>click here</a></div>
                <input
                    type="file"
                    id="x12files"
                    name="x12files"
                    multiple
                    onChange={handleAddFile}
                />
            </div>
            <div data-role="dropzonefiles">
                {files.length === 0 && (
                    <div className="centered">No selected files to upload</div>
                )}
                {files.length > 0 && (
                    <>
                        <h4>Selected Files</h4>
                        <ul data-role="uploadedfiles">
                            {files.map((file, index) => {
                                return (
                                    <li key={index}>
                                        <div data-role="fileinfo"><b>{file.name}</b> ({file.size} bytes)</div>
                                        <div data-role="fileactions">
                                            <div onClick={() => handleRemoveFile(index)}>
                                                <i className="bi bi-trash"></i>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </>
                )}
            </div>
        </section>
    );
};

export default X12MultipleFileUpload;
import { React, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createHttpClient, handleRemoteError } from '../utilities/httpclient';
import { AbdsButton, AbdsCheckbox } from '@abds/react-bindings';
import createUserSessionActivity from '../utilities/usersessiontracking';
import { createDisplayAreaMessengerContext, DisplayAreaMessenger } from './displayareamessenger/DisplayAreaMessenger';
import { APPLICATION_API_ENDPOINT } from '../utilities/constants';
import '../styles/absgtable.css';

export function ExpirationEventPartners(props)
{
    const navigate = useNavigate();
    const params = useParams();

    const [showLoading, setShowLoading] = useState(true);
    const [expirationEventGUID, setExpirationEventGUID] = useState(params.expirationEventGUID);
    const [expirationEventPartnerList, setExpirationEventPartnerList] = useState([]);
    let [messageContext, setMessageContext] = useState(createDisplayAreaMessengerContext());

    const getExpirationEventPartnersByExpirationEventGUID = async eeGUID => {
        setMessageContext({});
        const httpclient = createHttpClient();
        const criteria = {
            'ExpirationEventGUID': eeGUID,
            'ExpirationEvent_PartnersGUID': null,
            'Active': null
        };
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/certificate/expirationeventpartnerstatuses`, criteria).then(response => {
            setShowLoading(false);
            setExpirationEventGUID(eeGUID);
            const data = response?.data || [];
            setExpirationEventPartnerList(data);

            if (data.length === 0)
            {
                messageContext.information.message = "There are no partners associated with this expiration event";
                setMessageContext(messageContext);
            }
        })
        .catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    const onClickGoToCertificateManagementDetails = e => {
        e.preventDefault();

        if (!expirationEventGUID)
            return;

        navigate(`/certificatedetails/${expirationEventGUID}`);
        return;
    };

    const showErrorMessage = msg => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        messageContext.error.message = msg;
        setMessageContext(messageContext);
    };

    const onClickSelectPartner = async e => {
        e.preventDefault();
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        const label = e.target.getAttribute('data-label');
        const index = +e.target.getAttribute('data-index');
        const newvalue = !!e.target.checked ? 'true' : 'false';
        const criteria = {
            'ExpirationEventGUID': null,
            'ExpirationEventPartnerGUID': e.target.getAttribute('data-key'),
            'Active': !!e.target.checked
        };
        createUserSessionActivity(window.location.pathname, 'onClickSelectPartner', criteria);
        const httpclient = createHttpClient();
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/certificate/saveexpirationeventpartnerstatus`, criteria).then(response => {
            expirationEventPartnerList[index].active = criteria.Active;
            setExpirationEventPartnerList(expirationEventPartnerList);
            messageContext.success.message = `Status for ${label} successfully changed to ${newvalue}!`;
            setMessageContext(messageContext);
        })
        .catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    useEffect(() => {
        createUserSessionActivity(window.location.pathname);
        getExpirationEventPartnersByExpirationEventGUID(expirationEventGUID);
    }, []);

    useEffect(() => {
        const items = document.getElementsByTagName('abds-checkbox');
        Array.from(items || []).forEach(item => {
            item.removeEventListener('abdsChange', onClickSelectPartner); //JUST IN CASE something is attached
            item.addEventListener('abdsChange', onClickSelectPartner);
        });
        return () => {
            const items = document.getElementsByTagName('abds-checkbox');
            Array.from(items || []).forEach(item => item.removeEventListener('abdsChange', onClickSelectPartner));
        };
    }, [expirationEventPartnerList]);

    return (
        <div>
            <h3>Expiration Event Partners Management</h3>

            <hr className="abcblue" />

            <DisplayAreaMessenger context={messageContext} />

            <p>
                <AbdsButton onClick={onClickGoToCertificateManagementDetails}>
                    Return to Certificate Management Details
                </AbdsButton>
            </p>

            <hr className="abcblue" />

            <p>Choose from the list below the partners who should receive email notifications associated with this certificate.</p>
            {
                !!showLoading &&
                <p className="centered">Loading partner list, please wait...</p>
            }
            {
                !showLoading && expirationEventPartnerList.length === 0 &&
                <></>
            }
            {
                !showLoading && expirationEventPartnerList.length > 0 &&
                <table className="absgtable auxiliary">
                    <thead>
                        <tr>
                            <th>Partner Name</th>
                            <th>Application Type</th>
                            <th>Application Type Reference</th>
                            <th className="centered">Active</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        expirationEventPartnerList.length > 0 && expirationEventPartnerList.map((item,i) => {
                            return (
                                <tr key={i}>
                                    <td>{item.partnerName}</td>
                                    <td>{item.applicationTypeLabel}</td>
                                    <td>{item.applicationTypeReference}</td>
                                    <td className="centered">
                                        <AbdsCheckbox
                                            data-index={i}
                                            data-label={item.partnerName}
                                            data-key={item.expirationEvent_PartnersGUID}
                                            name={`selectpartner${i}`}
                                            checked={item.active}>
                                        </AbdsCheckbox>
                                    </td>
                                </tr>)
                        })
                    }
                    </tbody>
                </table>
            }
            {
                !showLoading && expirationEventPartnerList.length > 0 &&
                <>
                    <hr className="abcblue" />
                    <p>
                        <AbdsButton onClick={onClickGoToCertificateManagementDetails}>
                            Return to Certificate Management Details
                        </AbdsButton>
                    </p>
                </>
            }
        </div>
    );
}
import { React, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { createHttpClient, handleRemoteError } from '../../utilities/httpclient';
import { AbdsButton, AbdsInput } from '@abds/react-bindings';
import createUserSessionActivity from '../../utilities/usersessiontracking';
import { createDisplayAreaMessengerContext, DisplayAreaMessenger } from '../displayareamessenger/DisplayAreaMessenger';
import { APPLICATION_API_ENDPOINT } from '../../utilities/constants';
import X12MultipleFileUpload from '../auxiliary/X12MultipleFileUpload';
import { useKeyPressEnter } from '../../customhooks/useKeyPressEnter';

import '../../styles/main.css';
import '../../styles/searchquerycontainer.css';
import '../../styles/absgtable.css';
import '../../styles/x12.css';

export function X12Create(props)
{
    const navigate = useNavigate();
    let [messageContext, setMessageContext] = useState(createDisplayAreaMessengerContext());

    const [RITM, setRITM] = useState('');
    const [caseName, setCaseName] = useState('');
    const [files, setFiles] = useState([]);

    const rCaseName = useRef(null);
    const rRITM = useRef(null);
    const rSubmitButton = useRef(null);

    const onRITMChange = e => setRITM(e.target.value);

    const onCaseNameChange = e => setCaseName(e.target.value);

    const onClickReset = e => {
        e.preventDefault();
        setMessageContext({});
        setCaseName('');
        setRITM('');
        setFiles([]);
    };

    const onClickSave = e => {
        e.preventDefault();
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();

        if (caseName.trim().length === 0)
            messageContext.error.details.push('A name is required to open a case');

        if (RITM.trim().length === 0)
            messageContext.error.details.push('A RITM is required to open a case');

        if (files.length === 0)
            messageContext.error.details.push('At least one (1) file is required to open a case');

        if (messageContext.error.details.length > 0)
        {
            messageContext.error.message = "One or more errors were found";
            setMessageContext(messageContext);
        }
        else
        {
            createX12Case();
        }
    };

    const createX12Case = () => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        const httpclient = createHttpClient();
        const dataActivity = {
            'X12CaseGUID': '00000000-0000-0000-0000-000000000000',
            'CaseName': caseName,
            'RITM': RITM,
            'CreatedBy_UserKey': 0,
            'UploadFiles': []
        };

        const data = new FormData();
        data.append('CaseName', dataActivity.CaseName);
        data.append('RITM', dataActivity.RITM);
        data.append('X12CaseGUID', dataActivity.X12CaseGUID);
        data.append('CreatedBy_UserKey', dataActivity.CreatedBy_UserKey);

        for (let i = 0; i < files.length; i++) {
            data.append('UploadFiles', files[i]);
            dataActivity.UploadFiles.push({
                'name': files[i].name,
                'size': files[i].size
            });
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        createUserSessionActivity(window.location.pathname, 'createX12Case', dataActivity);
        httpclient.post(`${APPLICATION_API_ENDPOINT}/X12Parser/savex12case`, data, config).then(response => {
            messageContext.success.message = `The X12 case for ${caseName} has successfully been created!`;
            setMessageContext(messageContext);
        }).catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    const showErrorMessage = msg => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        messageContext.error.message = msg;
        setMessageContext(messageContext);
    };

    useKeyPressEnter(rSubmitButton);

    useEffect(() => {
        createUserSessionActivity(window.location.pathname);
    }, []);

    useEffect(() => {
        setMessageContext({});
    }, [files]);

    useEffect(() => {
        rCaseName.current?.addEventListener('abdsInput', onCaseNameChange);
        rRITM.current?.addEventListener('abdsInput', onRITMChange);

        return () => {
            rCaseName.current?.removeEventListener('abdsInput', onCaseNameChange);
            rRITM.current?.removeEventListener('abdsInput', onRITMChange);
        };
    }, [rCaseName, rRITM]);

    return (
        <div>
            <h3>X12 Validator: New Case</h3>
            <hr className="abcblue" />

            <DisplayAreaMessenger context={messageContext} />

            <h4>Case Information</h4>

            <div data-role="detailheader">
                <div>
                    <div data-role="group">
                        <div>
                            <AbdsInput
                                type="text"
                                width="full"
                                name="usr"
                                required
                                ref={rCaseName}
                                value={caseName}
                                tooltip="Enter case name here"
                                placeholder="Case Name">
                                Case Name
                            </AbdsInput>
                        </div>
                    </div>
                </div>
                <div>
                    <div data-role="group">
                        <div>

                        </div>
                    </div>
                </div>
                <div>
                    <div data-role="group">
                        <div>
                            <AbdsInput
                                type="text"
                                width="full"
                                name="usr"
                                required
                                ref={rRITM}
                                value={RITM}
                                tooltip="Enter RITM here"
                                placeholder="RITM">
                                RITM
                            </AbdsInput>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <h4>Case List</h4>

            <X12MultipleFileUpload files={files} filesHandler={setFiles} />

            <hr />

            <div className="buttoncontainer">
                <AbdsButton name="cancelsettings" onClick={onClickReset} appearance="outline" palette="brand">Reset</AbdsButton>
                &nbsp;&nbsp;&nbsp;
                <AbdsButton onClick={onClickSave} ref={rSubmitButton}>Save</AbdsButton>
            </div>

        </div>
    );
}
import React from 'react';
import { NavItem } from 'reactstrap';
import { URL_COMPANY, URL_ABOUT_US, URL_PRIVACY_STATEMENT, URL_TERMS_CONDITIONS, COPYRIGHT, COMPANY_NAME, USE_CENCORA_BRANDING, APP_NAME } from '../utilities/constants';

import celogo from '../images/cencora_logo_footer.png';
import ablogo from '../images/ab_logo_footer.png';

import '../styles/footer.css';

export function Footer(props) {
    const logo = (!!USE_CENCORA_BRANDING)
        ? celogo
        : ablogo;

    return (
        <footer>
            <div className="d-flex flex-grow flex-row align-items-center justify-content-center">
                <div className=""></div>
                <div className="">
                    <ul className="navbar-nav flex-grow flex-row d-flex align-items-center justify-content-center">
                        <NavItem>
                            <a target="_blank" href={URL_ABOUT_US} target="_blank">About Us</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                        </NavItem>
                        <NavItem>
                            <a target="_blank" href={URL_TERMS_CONDITIONS}>Terms and Conditions</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                        </NavItem>
                        <NavItem>
                            <a target="_blank" href={URL_PRIVACY_STATEMENT}>Privacy Statement</a>
                        </NavItem>
                    </ul>
                    <div>{COPYRIGHT}</div>
                    <div className="centered">{APP_NAME}</div>
                </div>
                <div className="">
                    <p className="float-right">
                        <a href={URL_COMPANY} rel="external" target="_blank">
                            <img className="logo" src={logo} alt={COMPANY_NAME} title={COMPANY_NAME} />
                        </a>
                    </p>
                </div>
            </div>
        </footer>
    );
}
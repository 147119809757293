const ENTERKEY = 13;

const APP_AUTH_TOKEN = 'CCSAB';

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const REGEX_PHONE = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

const checkUserTokenFormat = subject => {
    if (!subject || subject === '00000000-0000-0000-0000-000000000000')
        return false;

    return !!(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/.test(subject));
};

//controls Cencora visibilty - be sure to set to FALSE for AB
const USE_CENCORA_BRANDING = true;

const EMAIL_MYEDI_SUPPORT = "myEDIApplicationSupport@amerisourcebergen.com";

const URL_COMPANY = "https://www.cencora.com"

const URL_ABOUT_US = "https://www.cencora.com";

const URL_PRIVACY_STATEMENT = "https://www.cencora.com/global-privacy-statement-overview";

const URL_TERMS_CONDITIONS = "https://www.cencora.com/global-terms-and-conditions";

const COMPANY_NAME = "Cencora";

const COPYRIGHT = `Copyright \u00AE ${new Date().getFullYear().toString()} ${COMPANY_NAME} Corporation. All rights reserved.`;

const EVN_TKNS = {
    'DEV'           : 'localhost',
    'TEST'          : 'be2mtyi2t001.cfd.',
    'STAGE'         : 'myedistage.',
    'PRODUCTION'    : 'myedi.'
};

const APPLICATION_URL = (() => {
    const host = window.location.host.toLowerCase().trim();
    const appDomainURLs = {
        'DEV'           : 'https://localhost:44345',
        'TEST'          : 'https://be2mtyi2t001.cfd.amerisourcebergen.com',
        'STAGE'         : 'https://myedistage.amerisourcebergen.com',
        'PRODUCTION'    : 'https://myedi.amerisourcebergen.com'
    };

    switch (true) {
        case (host.indexOf(EVN_TKNS.PRODUCTION) >= 0):
            return appDomainURLs.PRODUCTION;
        case (host.indexOf(EVN_TKNS.STAGE) >= 0):
            return appDomainURLs.STAGE;
        case (host.indexOf(EVN_TKNS.TEST) >= 0):
            return appDomainURLs.TEST;
        default:
            return appDomainURLs.DEV;
    };
})();

const APPLICATION_API_ENDPOINT = (() => {
    const host = window.location.host.toLowerCase().trim();

    if (host.indexOf(EVN_TKNS.DEV) >= 0)
        return 'https://localhost:44339';

    if (host.indexOf(EVN_TKNS.TEST) >= 0)
        return 'https://BE2MTYI4T001.npd.amerisourcebergen.com';

    return `${APPLICATION_URL}/api`;
})();

const APP_NAME = (() => {
    const label = 'myEDI';
    const host = window.location.host.toLowerCase().trim();
    const environmentLabel = (() => {
        switch (true) {
            case (host.indexOf(EVN_TKNS.PRODUCTION) >= 0):
                return '';
            case (host.indexOf(EVN_TKNS.STAGE) >= 0):
                return 'Staging';
            case (host.indexOf(EVN_TKNS.TEST) >= 0):
                return 'Testing';
            case (host.indexOf(EVN_TKNS.DEV) >= 0 || host.indexOf('127.0.0.1') >= 0):
                return 'Development';
        };

        return value;
    })();

    return (environmentLabel.length === 0)
        ? label
        : `${label} - ${environmentLabel}`;
})();

export {
    ENTERKEY, REGEX_EMAIL, REGEX_PHONE, checkUserTokenFormat,
    URL_COMPANY, URL_ABOUT_US, URL_PRIVACY_STATEMENT, URL_TERMS_CONDITIONS,
    EMAIL_MYEDI_SUPPORT, COPYRIGHT, COMPANY_NAME, USE_CENCORA_BRANDING,
    APPLICATION_API_ENDPOINT, APPLICATION_URL, APP_NAME, APP_AUTH_TOKEN
};
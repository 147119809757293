import { React, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { createHttpClient } from '../utilities/httpclient';
import CertificateTypeDisplayList from './auxiliary/CertificateTypeDisplayList';
import LocalStorageController from '../utilities/lsc';
import { AbdsButton, AbdsLabel, AbdsInput } from '@abds/react-bindings';
import createUserSessionActivity from '../utilities/usersessiontracking';
import { checkUserTokenFormat, REGEX_EMAIL, COMPANY_NAME, APPLICATION_API_ENDPOINT, APP_AUTH_TOKEN } from '../utilities/constants';
import { createDisplayAreaMessengerContext, DisplayAreaMessenger } from './displayareamessenger/DisplayAreaMessenger';

import { useKeyPressEnter } from '../customhooks/useKeyPressEnter';

import '../styles/absgtable.css';
import '../styles/certificatedetails.css';
import '../styles/certificateselfservice.css';

export function CertificateSelfService (props)
{
    const emptyCertificateDetailState = {
        certificateID: 0,
        communityID: 0
    };

    const rName = useRef(null);
    const rEmail = useRef(null);
    const rPhone = useRef(null);
    const rAS2ID = useRef(null);
    const rSubmitButton = useRef(null);

    const AS2ID_Tooltip = `For validation purposes, please enter your AS2 ID used to connect to ${COMPANY_NAME}'s URL.  This is NOT the AS2 ID in the email we sent you.`;
    const [showDownloadPanel, setShowDownloadPanel] = useState(null);
    const [partnerName, setPartnerName] = useState('...');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [AS2ID, setAS2ID] = useState('');
    const [communityName, setCommunityName] = useState('');
    const [expiringCertificateDetails, setExpiringCertificateDetails] = useState(null);
    const [newCertificateDetails, setNewCertificateDetails] = useState(null);
    const [isClosedEvent, setIsClosedEvent] = useState(false);
    let [messageContext, setMessageContext] = useState({});

    const { userToken } = useParams();

    const showErrorMessage = msg => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        messageContext.error.message = msg;
        setMessageContext(messageContext);
    };

    const onChangeName = e => setName(e.target.value);

    const onChangeEmail = e => setEmail(e.target.value);

    const onChangePhone = e => setPhone(e.target.value);

    const onChangeAS2ID = e => setAS2ID(e.target.value);

    const onSubmitAS2ID = e => {
        e.preventDefault();
        (!checkInputData() || createUserSessionForTokenedUser());
    };

    const createUserSessionForTokenedUser = async () => {
        const httpclient = createHttpClient();
        const data = {
            'UserToken': userToken,
            'AS2ID': AS2ID,
            'Name': name,
            'Email': email,
            'Phone': phone
        };
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/systemaccess/tokenlogin`, data).then(response => {
            if (!response?.data)
            {
                showErrorMessage('Unable to login');
                setShowDownloadPanel(false);
                return;
            }
            const lsc = new LocalStorageController();
            lsc.save(APP_AUTH_TOKEN, response.data.toString());
            createUserSessionActivity(window.location.pathname);
            setShowDownloadPanel(true);
        })
        .catch(e => showErrorMessage(e.response?.data || e.message));
    };

    const onClickDownloadCertificate = async (event, argCertificateID, fileExtension) => {
        event.preventDefault();
        setMessageContext({});
        const data = {
            'CommunityID': 0,
            'CertificateID': +argCertificateID,
            'FileExtension': fileExtension,
            'UserToken': userToken
        };
        const httpclient = createHttpClient();
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/certificateselfservice/download`, data).then(response => {
            const isValidResponse = (r => {
                return !!r && !!r.data &&
                    r.data.hasOwnProperty('fileContents') &&
                    r.data.hasOwnProperty('contentType') &&
                    r.data.hasOwnProperty('fileDownloadName');
            })(response);

            if (!isValidResponse)
            {
                showErrorMessage('There was a problem fetching your requested certificate');
                return;
            }
            createUserSessionActivity(window.location.pathname, `onClickDownloadCertificate - ${fileExtension}`, data);
            const hl = document.createElement('a');
            hl.href = `data:application/octet-stream;base64,${response.data.fileContents}`;
            hl.target = '_blank';
            hl.download = response.data.fileDownloadName;
            hl.click();
        })
        .catch(e => showErrorMessage(e.response?.data || e.message))
    };

    const onClickDownloadICS = async (event, argCertificateID) => {
        event.preventDefault();
        setMessageContext({});
        const data = {
            'CommunityID': 0,
            'CertificateID': +argCertificateID,
            'FileExtension': 'ics',
            'UserToken': userToken
        };
        const httpclient = createHttpClient();
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/certificateselfservice/calendarreminder`, data).then(response => {
            const isValidResponse = (r => {
                return !!r && !!r.data &&
                    r.data.hasOwnProperty('fileContents') &&
                    r.data.hasOwnProperty('contentType') &&
                    r.data.hasOwnProperty('fileDownloadName');
            })(response);

            if (!isValidResponse) {
                showErrorMessage('There was a problem fetching your requested export');
                return;
            }
            const hl = document.createElement('a');
            hl.href = `data:application/octet-stream;base64,${response.data.fileContents}`;
            hl.target = '_blank';
            hl.download = response.data.fileDownloadName;
            hl.click();
        })
        .catch(e => showErrorMessage(e.response?.data || e.message))
    };

    const checkInputData = () => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();

        if (name.trim().length === 0)
            messageContext.error.details.push("Name is required");

        if (email.trim().length === 0)
        {
            messageContext.error.details.push("Email is required");
        }
        else if (!REGEX_EMAIL.test(email))
        {
            messageContext.error.details.push("Email format is invalid");
        }

        if (phone.trim().length === 0)
            messageContext.error.details.push("Phone number is required");

        if (AS2ID.trim().length === 0)
            messageContext.error.details.push("AS2 ID is required");

        if (messageContext.error.details.length > 0)
        {
            messageContext.error.message = "Errors were found in processing:";
            setMessageContext(messageContext);
            return false;
        }

        return true;
    }

    useKeyPressEnter(rSubmitButton);

    useEffect(() => {
        if (!checkUserTokenFormat(userToken))
        {
            messageContext = createDisplayAreaMessengerContext();
            messageContext.error.message = "Please click on the link from your email to load this page properly.";
            setMessageContext(messageContext);
            return;
        }
        const httpclient = createHttpClient();
        const data = {
            'UserToken': userToken,
            'AS2ID': '',
            'Name': '',
            'Email': '',
            'Phone':''
        };
        httpclient.post(`${APPLICATION_API_ENDPOINT}/certificateselfservice/initialaccess`, data)
            .then(response => setPartnerName(`for ${response?.data?.partnerLabel}`))
            .catch(e => {
                const message = e?.response?.data || e.message;
                const isclosed = !!(message || '').toString().toLowerCase().includes('closed');
                setIsClosedEvent(isclosed);

                if (isclosed)
                {
                    messageContext = createDisplayAreaMessengerContext();
                    messageContext.information.message = message;
                    messageContext.information.masthead = "CLOSED";
                    setMessageContext(messageContext);
                }
                else
                {
                    showErrorMessage(message);
                }
            });
    }, []);

    useEffect(() => {
        rName.current?.addEventListener('abdsInput', onChangeName);
        rEmail.current?.addEventListener('abdsInput', onChangeEmail);
        rPhone.current?.addEventListener('abdsInput', onChangePhone);
        rAS2ID.current?.addEventListener('abdsInput', onChangeAS2ID);

        return () => {
            rName.current?.removeEventListener('abdsInput', onChangeName);
            rEmail.current?.removeEventListener('abdsInput', onChangeEmail);
            rPhone.current?.removeEventListener('abdsInput', onChangePhone);
            rAS2ID.current?.removeEventListener('abdsInput', onChangeAS2ID);
        };
    }, [rName, rEmail, rPhone, rAS2ID]);

    useEffect(() => {
        if (showDownloadPanel === true)
        {
            const httpclient = createHttpClient();
            const data = {
                'UserToken': userToken,
                'AS2ID': AS2ID,
                'Name': name,
                'Email': email,
                'Phone': phone
            };
            httpclient.post(`${APPLICATION_API_ENDPOINT}/certificateselfservice/selfservicedata`, data).then(response => {
                setExpiringCertificateDetails(response.data?.expired || emptyCertificateDetailState);
                setNewCertificateDetails(response.data?.newcerts || emptyCertificateDetailState);
                setCommunityName(response.data.communityName);
            })
            .catch(e => showErrorMessage(e.response?.data || e.message))
        }
    }, [showDownloadPanel]);

    return (
        <div>
            <h3>Certificate Management: Download {partnerName}</h3>

            <hr className="abcblue" />

            <DisplayAreaMessenger context={messageContext} />
            {
                (checkUserTokenFormat(userToken) && !isClosedEvent) &&
                <>
                    <h4>Please complete the following information to update and download the certificates below.</h4>

                    <div data-role="selfserviceinformation">
                        <div data-role="informationitem">

                            <span className="selfservicetextboxcontainer">
                                <AbdsInput
                                    type="text"
                                    width="full"
                                    name="name"
                                    required
                                    ref={rName}
                                    value={name}
                                    tooltip="Enter first and last name"
                                    placeholder="Enter first and last name">
                                    First and Last Name
                                </AbdsInput>
                            </span>

                        </div>
                        <div data-role="informationitem">

                            <span className="selfservicetextboxcontainer">
                                <AbdsInput
                                    type="text"
                                    width="full"
                                    name="email"
                                    required
                                    ref={rEmail}
                                    value={email}
                                    tooltip="Enter email address"
                                    placeholder="Enter email address">
                                    Email
                                </AbdsInput>
                            </span>

                        </div>
                        <div data-role="informationitem">

                            <span className="selfservicetextboxcontainer">
                                <AbdsInput
                                    type="text"
                                    width="full"
                                    name="phone"
                                    required
                                    ref={rPhone}
                                    value={phone}
                                    tooltip="Enter phone number"
                                    placeholder="Enter phone number">
                                    Phone
                                </AbdsInput>
                            </span>

                        </div>
                        <div data-role="informationitem">

                            <span className="selfservicetextboxcontainer">
                                <AbdsInput
                                    type="text"
                                    width="full"
                                    name="AS2ID"
                                    required
                                    ref={rAS2ID}
                                    value={AS2ID}
                                    helperText="This is your AS2 ID, not the AS2 ID in the email."
                                    tooltip={AS2ID_Tooltip}
                                    placeholder="Enter Your AS2 ID">
                                    Your AS2 ID
                                </AbdsInput>
                            </span>

                        </div>
                        <div data-role="informationitem">
                            <AbdsButton ref={rSubmitButton} onClick={onSubmitAS2ID}>Submit</AbdsButton>
                        </div>
                        <div data-role="informationitem">

                        </div>
                    </div>
                </>
            }
            {
                showDownloadPanel === true &&
                <>
                    <div data-role="detailheader">
                        <div>
                            <div data-role="group">
                                <AbdsLabel>Community Name:</AbdsLabel>
                                <div>{communityName}</div>
                            </div>
                        </div>
                        <div>
                            <div data-role="group">
                                <AbdsLabel>AS2 ID:</AbdsLabel>
                                <div>{AS2ID}</div>
                            </div>
                        </div>
                        <div>
                            <div data-role="group">
                                <AbdsLabel>&nbsp;</AbdsLabel>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>

                    <h4>Expiring Certificate Details</h4>
                    {
                        expiringCertificateDetails === null &&
                        (<h3 className="center">Loading expiring certificate data...</h3>)
                    }
                    {
                        expiringCertificateDetails != null &&
                        +expiringCertificateDetails?.communityID === 0 &&
                        +expiringCertificateDetails?.certificateID === 0 &&
                        (<h3 className="center">No certificate data</h3>)
                    }
                    {
                        expiringCertificateDetails != null &&
                        +expiringCertificateDetails?.certificateID > 0 &&
                        +expiringCertificateDetails?.communityID > 0 &&
                        <>
                            <table className="absgtable auxiliary">
                                <thead>
                                    <tr>
                                        <th>Certificate Name:</th>
                                        <th>Serial Number:</th>
                                        <th>Begin Date:</th>
                                        <th>End Date:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{expiringCertificateDetails.certificateName}</td>
                                        <td>{expiringCertificateDetails.serialNumber}</td>
                                        <td>{expiringCertificateDetails.beginDateDisplay}</td>
                                        <td>{expiringCertificateDetails.endDateDisplay}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="absgtable auxiliary urltable">
                                <thead>
                                    <tr>
                                        <th>Type:</th>
                                        <th colSpan="4">AS2 URL:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        +(expiringCertificateDetails?.remoteURLs?.length || 0) > 0 &&
                                        expiringCertificateDetails.remoteURLs.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><CertificateTypeDisplayList items={item.certificateTypes} /></td>
                                                    <td colSpan="3">{item.remoteURI}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </>
                    }

                    <hr className="abcblue" />

                    <h4>New Certificate Details</h4>
                    {
                        newCertificateDetails === null &&
                        (<h3 className="center">Loading new certificate data...</h3>)
                    }
                    {
                        newCertificateDetails != null &&
                        +newCertificateDetails?.communityID === 0 &&
                        +newCertificateDetails?.certificateID === 0 &&
                        (<h3 className="center">No certificate data</h3>)
                    }
                    {
                        newCertificateDetails != null &&
                        +newCertificateDetails?.certificateID > 0 &&
                        +newCertificateDetails?.communityID > 0 &&
                        (<>
                            <table className="absgtable auxiliary">
                                <thead>
                                    <tr>
                                        <th>Certificate Name:</th>
                                        <th>Serial Number:</th>
                                        <th>Begin Date:</th>
                                        <th>End Date:</th>
                                        <th colSpan="3" className="centered">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{newCertificateDetails.certificateName}</td>
                                        <td>{newCertificateDetails.serialNumber}</td>
                                        <td>{newCertificateDetails.beginDateDisplay}</td>
                                        <td>{newCertificateDetails.endDateDisplay}</td>
                                        <td className="centered">
                                            <AbdsButton onClick={event => onClickDownloadCertificate(event, newCertificateDetails.certificateID, 'CER')}>Download CER</AbdsButton>
                                        </td>
                                        <td className="centered">
                                            <AbdsButton onClick={event => onClickDownloadCertificate(event, newCertificateDetails.certificateID, 'P7B')}>Download P7B</AbdsButton>
                                        </td>
                                        <td className="centered">
                                            <AbdsButton onClick={event => onClickDownloadICS(event, newCertificateDetails.certificateID)} appearance="outline" palette="brand">Download Reminder (.ics)</AbdsButton>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="absgtable auxiliary urltable">
                                <thead>
                                    <tr>
                                        <th>Type:</th>
                                        <th colSpan="6">AS2 URL:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        +(newCertificateDetails?.remoteURLs?.length || 0) > 0 &&
                                        newCertificateDetails.remoteURLs.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><CertificateTypeDisplayList items={item.certificateTypes} /></td>
                                                    <td colSpan="3">{item.remoteURI}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </>)
                    }
                </>
            }
        </div>
    );
}
import { React, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { createHttpClient, handleRemoteError } from '../../utilities/httpclient';
import { AbdsButton } from '@abds/react-bindings';
import createUserSessionActivity from '../../utilities/usersessiontracking';
import { createDisplayAreaMessengerContext, DisplayAreaMessenger } from '../displayareamessenger/DisplayAreaMessenger';
import { APPLICATION_API_ENDPOINT } from '../../utilities/constants';
import { useKeyPressEnter } from '../../customhooks/useKeyPressEnter';

import '../../styles/main.css';
import '../../styles/searchquerycontainer.css';
import '../../styles/absgtable.css';

export function X12Home(props) {
    const refSpanSortDirection = useRef(null);
    const rSubmitButton = useRef(null);

    let [random, setRandom] = useState(1);

    const [dashboardData, setDashboardData] = useState(null);
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [sortDirectionCssClass, setSortDirectionCssClass] = useState('');
    let [messageContext, setMessageContext] = useState(createDisplayAreaMessengerContext());

    const navigate = useNavigate();

    const fetchDashboardData = async criteria => {
        const httpclient = createHttpClient();
        await httpclient.post(`${APPLICATION_API_ENDPOINT}/x12/home`, criteria).then(response => {
            setDashboardData(response.data);
            random = Math.floor(Math.random() * 99999999); //force component re-render
            setRandom(random);
        })
        .catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    }

    const onChangeSearchQuery = e => setSearchQuery(e.target.value);

    const onClickResetList = async e => {
        e.preventDefault();
        setSearchQuery('');
        setSortColumn('');
        setSortDirection('');
        const data = {
            'SearchQuery': '',
            'SortColumn': '',
            'SortDirection': ''
        };
        createUserSessionActivity(window.location.pathname, 'onClickResetList', data);
        await fetchDashboardData(data);
    };

    const showErrorMessage = msg => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        messageContext.error.message = msg;
        setMessageContext(messageContext);
    };

    const onClickExecuteSearch = async e => {
        e.preventDefault();
        setSortColumn('');
        setSortDirection('');
        const data = {
            'SearchQuery': searchQuery,
            'SortColumn': '',
            'SortDirection': ''
        };
        createUserSessionActivity(window.location.pathname, 'onClickExecuteSearch', data);
        await fetchDashboardData(data);
    };

    const columnmap = [
        { 'label': 'Case Name', 'accessor': 'casename' },
        { 'label': 'Status', 'accessor': 'status' },
        { 'label': 'Processed', 'accessor': 'processed' },
        { 'label': 'Created By', 'accessor': 'user' },
        { 'label': 'Created Date', 'accessor': 'created' },
        { 'label': 'Error', 'accessor': 'errormessage' }
    ];

    const handleNewCase = () => navigate('/x12/create');

    const handleEditCase = (e, item) => {
        e.preventDefault();
        navigate(`/x12/details/${item.x12CaseGUID}`);
        return;
    };

    const handleTableColumnOnClick = async e => {
        e.preventDefault();
        const newAccessor = e.target.getAttribute('data-accessor');
        const newSortDirection = (() => {
            const target = refSpanSortDirection.current;

            if (!target || sortColumn !== newAccessor)
                return 'asc';

            return (target.getAttribute('data-direction') === 'asc') ? 'desc' : 'asc';
        })();
        setSortColumn(newAccessor);
        setSortDirection(newSortDirection);
        const data = {
            'SearchQuery': searchQuery,
            'SortColumn': newAccessor,
            'SortDirection': newSortDirection
        };
        setSortDirectionCssClass((newSortDirection.toLowerCase().trim() === 'desc')
            ? 'bi bi-caret-down-fill'
            : 'bi bi-caret-up-fill');
        createUserSessionActivity(window.location.pathname, 'handleTableColumnOnClick', data);
        await fetchDashboardData(data);
    };

    useKeyPressEnter(rSubmitButton);

    useEffect(() => {
        createUserSessionActivity(window.location.pathname);
        fetchDashboardData({
            'SearchQuery': '',
            'SortColumn': '',
            'SortDirection': ''
        });
    }, []);

    return (
        <div>
            <h3>X12 Validator: Dashboard</h3>
            <hr className="abcblue" />

            <DisplayAreaMessenger context={messageContext} />

            <h4>Case List</h4>
            {
                dashboardData === null &&
                (<h4 className="center">Loading X12 data...</h4>)
            }
            {
                dashboardData?.length === 0 &&
                (<>
                    <h4 className="center">No X12 data</h4>
                    <div className="alignright">
                        <AbdsButton onClick={handleNewCase}>Create New Case</AbdsButton>
                    </div>
                </>)
            }
            {
                dashboardData?.length > 0 &&
                (
                    <>
                        <div className="right">
                            <div data-role="searchquerycontainer">
                                <input type="text" name="q" id="q" placeholder="Enter Case Name" value={searchQuery} onChange={onChangeSearchQuery} />
                                <AbdsButton ref={rSubmitButton} onClick={onClickExecuteSearch}>Filter</AbdsButton>
                            </div>
                            <div className="reset">
                                <a href="#" onClick={onClickResetList}><strong><span className="bi bi-arrow-counterclockwise"></span>&nbsp;Reset List</strong></a>
                            </div>
                        </div>
                        <div className="alignright">
                            <AbdsButton onClick={handleNewCase}>Create New Case</AbdsButton>
                        </div>
                        <table className="absgtable certificates">
                            <thead>
                                <tr>
                                    {
                                        columnmap.map(({ label, accessor }) => {
                                            return (
                                                <th key={accessor}>
                                                    {
                                                        (accessor === sortColumn) &&
                                                        <span ref={refSpanSortDirection} className={sortDirectionCssClass} data-direction={sortDirection}></span>
                                                    }
                                                    <a href="#" onClick={handleTableColumnOnClick} data-accessor={accessor}>{label}</a>
                                                </th>
                                            );
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dashboardData.map(item => {
                                        return (
                                            <tr key={+item.x12CaseGUID.replace('-', '')}>
                                                <td style={{width:'20%'}}>
                                                    {
                                                        item.formattedProcessedDateTime != null && item.formattedProcessedDateTime.length > 0 &&
                                                            (<a href="#" onClick={e => handleEditCase(e, item)}>{item.caseName}</a>)
                                                    }
                                                    {
                                                        (item.formattedProcessedDateTime == null || item.formattedProcessedDateTime.length === 0) &&
                                                        (<>{item.caseName}</>)
                                                    }
                                                </td>
                                                <td style={{width:'10%'}}>{item.statusLabel}</td>
                                                <td style={{width:'20%'}}>{item.formattedProcessedDateTime}</td>
                                                <td style={{width:'20%'}}>{item.username}</td>
                                                <td style={{width:'20%'}}>{item.formattedCreatedDateTime}</td>
                                                <td style={{width:'20%'}}>{item.errorMessage ?? ''}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <div className="alignright">
                            <AbdsButton onClick={handleNewCase}>Create New Case</AbdsButton>
                        </div>
                    </>
                )                 
            }
        </div>
    );
}